import { PageComponent } from '@root/pages/Type';
import TwoAuthApp from './TwoAuthApp';
import TwoAuthPin from './TwoAuthPin';

type TwoAuthProps = {
    type: 'pin' | 'app';
    pinResend?: boolean;
    autoFocus?: boolean;
};

const TwoAuth: PageComponent<TwoAuthProps> = ({
    type,
    pinResend,
    autoFocus,
}) => {
    if (type === 'app') return <TwoAuthApp autoFocus={autoFocus} />;
    return <TwoAuthPin pinResend={pinResend} />;
};

export default TwoAuth;
