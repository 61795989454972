import { ServerData, useAxios } from '@root/utils/axios';
import Noty from '@root/utils/Noty';
import useTimeAction from '@root/utils/useTimeAction';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Field } from '../Form/Index';
import styles from './styles.module.scss';

type RequestTwoAuthPinResponse = ServerData<{
    twoAuthEnabled?: boolean;
    rateLimited?: boolean;
}>;

type SendProps = {
    isResend: boolean;
    onSent: (isError: boolean) => void;
};

const SendButton: React.FC<SendProps> = ({ isResend, onSent }) => {
    const [isLoading, setIsLoading] = useState(false);
    const axios = useAxios();
    const { t } = useTranslation('uiComponentsTwoAuth');
    const sendPin = () => {
        setIsLoading(true);
        axios
            .post<RequestTwoAuthPinResponse>('/account/2fa/request-pin')
            .then(({ data }) => {
                if (data.twoAuthEnabled) {
                    onSent(true);
                    Noty({
                        type: 'error',
                        title: t('noty.title', {
                            defaultValue: 'PIN kodas',
                        }),
                        message: t('noty.twoAuthEnabled', {
                            defaultValue: 'Klaidinga uzklausa',
                        }),
                    });
                    return;
                }
                if (data.rateLimited) {
                    onSent(true);
                    Noty({
                        type: 'error',
                        title: t('noty.title', {
                            defaultValue: 'PIN kodas',
                        }),
                        message: t('noty.rateLimited', {
                            defaultValue: 'Uzklausa atlikta per greitai.',
                        }),
                    });
                    return;
                }
                onSent(false);
                if (isResend) {
                    Noty({
                        type: 'success',
                        title: t('noty.title', {
                            defaultValue: 'PIN kodas',
                        }),
                        message: t('noty.resended', {
                            defaultValue: 'Autorizacijos kodas išsiųstas',
                        }),
                    });
                } else {
                    Noty({
                        type: 'success',
                        title: t('noty.title', {
                            defaultValue: 'PIN kodas',
                        }),
                        message: t('noty.sended', {
                            defaultValue: 'Autorizacijos kodas išsiųstas',
                        }),
                    });
                }
            })
            .catch(() => {
                onSent(true);
                if (isResend) {
                    Noty({
                        type: 'error',
                        title: t('noty.title', {
                            defaultValue: 'PIN kodas',
                        }),
                        message: t('noty.failedToReSend', {
                            defaultValue: 'Nepavyko išsiųsti kodo',
                        }),
                    });
                } else {
                    Noty({
                        type: 'error',
                        title: t('noty.title', {
                            defaultValue: 'PIN kodas',
                        }),
                        message: t('noty.failedToSend', {
                            defaultValue: 'Nepavyko išsiųsti kodo',
                        }),
                    });
                }
            });
    };
    return (
        <Button
            className={styles.send}
            onClick={(e) => {
                e.preventDefault();
                sendPin();
            }}
            isLoading={isLoading}
        >
            {isResend ? (
                <Trans ns="uiComponentsTwoAuth" i18nKey="resendBtn">
                    Pakartoti
                </Trans>
            ) : (
                <Trans ns="uiComponentsTwoAuth" i18nKey="sendBtn">
                    Issiusti
                </Trans>
            )}
        </Button>
    );
};

const RequestPin: React.FC = () => {
    const [showSend, setShowSend] = useState(true);
    const [coolDown, setCoolDown] = useState(0);
    const onCoolEnd = useCallback(() => {
        setCoolDown(0);
    }, []);
    const { timeoutsec } = useTimeAction(
        onCoolEnd,
        !!coolDown ? coolDown * 1000 : null,
    );
    const onSent = useCallback((isError: boolean) => {
        setShowSend(false);
        setCoolDown(isError ? 6 : 30);
    }, []);
    if (!!coolDown) {
        return (
            <div className={styles.coolDown}>
                <Trans
                    ns="uiComponentsTwoAuth"
                    i18nKey="resendText"
                    values={{
                        timeoutsec,
                    }}
                >
                    Pakartotinis issiuntimas uz [[timeoutsec]] s.
                </Trans>
            </div>
        );
    }
    return <SendButton isResend={!showSend} onSent={onSent} />;
};

type TwoAuthPinProps = {
    pinResend?: boolean;
};

const TwoAuthPin: React.FC<TwoAuthPinProps> = ({ pinResend = true }) => {
    const { t } = useTranslation('uiComponentsTwoAuth');
    return (
        <div className={styles.pin_container}>
            <Field
                name="twoAuthCode"
                label={t('form.twoAuthPin', {
                    defaultValue: 'Autorizacijos kodas',
                })}
                hint={t('form.twoAuthPinHint', {
                    defaultValue: 'Naudojant el. pašto adresą',
                })}
                maxLength={6}
            />
            {pinResend && (
                <div className={styles.btn_container}>
                    <RequestPin />
                </div>
            )}
        </div>
    );
};

export default TwoAuthPin;
