import { userAuthLogin } from '@store/auth/actions';
import { LoginFormValues, LoginResponse } from '@store/auth/types';
import withForm, { FormHandlerInside, withFormProps } from '@utils/withForm';
import { useTranslation } from 'react-i18next';
import PrettyError from '@root/utils/errors/PrettyError';
import { Router as i18nRouter } from '@root/router';
import { PageComponent } from '@root/pages/Type';
import { AppDispatch, RootState } from '@root/store/types';
import { LoginSchemaInit } from '@root/utils/ValidationSchemas/Index';
import { connect, useDispatch } from 'react-redux';
import { ServerData } from '@root/utils/axios';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { CaptchaContextState, useCaptcha } from '@components/CaptchaProvider';
import Content from './Content';
import { listReset } from '@root/store/services';
import { useLiveHelp } from '@root/components/LiveHelpContext';
import TwoAuthContent from './TwoAuthContent';

const initialValues: LoginFormValues = {
    email: '',
    password: '',
    twoAuthCode: undefined,
};

type ContainerProps = withFormProps<LoginFormValues> & {
    currentLanguage: string | null;
    handleUserAuthAuthLogin: (
        values: LoginFormValues,
        captcha?: CaptchaContextState,
    ) => Promise<ServerData<LoginResponse>>;
};

const Container: PageComponent<ContainerProps> = ({
    currentLanguage,
    submitting,
    isValid,
    errorMessage,
    handleForm,
    handleUserAuthAuthLogin,
}) => {
    const router = useRouter();
    const returnToLogin = '';
    const { t } = useTranslation('login');
    const captcha = useCaptcha();
    const dispatch = useDispatch<AppDispatch>();

    const [showTwoAuth, setShowTwoAuth] = useState<'pin' | 'app' | boolean>(
        false,
    );

    const { wantHide } = useLiveHelp();
    const { setVisible } = captcha;

    useEffect(() => {
        i18nRouter.prefetchRoute('ui_services_list', currentLanguage);
        setVisible(true);
        wantHide(true);
        return () => {
            setVisible(false);
            wantHide(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formSubmit: FormHandlerInside<LoginFormValues> = ({ values }) => {
        return handleUserAuthAuthLogin(values, captcha).then((response) => {
            if (response.userNotFound) {
                throw new PrettyError(
                    t('form.errors.userNotFound', {
                        defaultValue: 'Vartotojas nerastas.',
                    }),
                );
            }
            if (response.blocked) {
                throw new PrettyError(
                    t('form.errors.blocked', {
                        defaultValue: 'Vartotojas yra užblokuotas.',
                    }),
                );
            }
            if (response.twoAuthCodePinRequired) {
                if (!showTwoAuth) {
                    setShowTwoAuth('pin');
                    return;
                }
            }
            if (response.twoAuthCodeAppRequired) {
                if (!showTwoAuth) {
                    setShowTwoAuth('app');
                    return;
                }
            }
            if (response.invalidTwoAuthCode) {
                throw new PrettyError(
                    t('form.errors.invalidTwoAuthCode', {
                        defaultValue: 'Neteisingas 2-autentifikavimo kodas.',
                    }),
                );
            }
            if (!response.success) {
                throw new PrettyError(
                    t('form.errors.fatalError', {
                        defaultValue:
                            'Nepavyko prisijungti. Prašome mėginti dar kartą.',
                    }),
                );
            }
            dispatch(listReset());
            if (returnToLogin) {
                return router.push(returnToLogin);
            }
            return i18nRouter.pushRoute(
                'ui_services_list',
                {},
                currentLanguage,
            );
        });
    };

    if (typeof showTwoAuth === 'string') {
        return (
            <TwoAuthContent
                type={showTwoAuth}
                onSubmitForm={handleForm(formSubmit)}
                submitting={submitting}
                isValid={isValid}
                errorMessage={errorMessage}
            />
        );
    }

    return (
        <Content
            onSubmitForm={handleForm(formSubmit)}
            submitting={submitting}
            isValid={isValid}
            errorMessage={errorMessage}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    currentLanguage: state.i18n.currentLanguage,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleUserAuthAuthLogin: (
        values: LoginFormValues,
        captcha?: CaptchaContextState,
    ) => dispatch(userAuthLogin(values, captcha)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    withForm<LoginFormValues>({
        initialValues,
        enableReinitialize: true,
        validationSchema: () => LoginSchemaInit(),
    })(Container),
);
