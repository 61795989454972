import { useCaptcha } from '@components/CaptchaProvider';
import { useLiveHelp } from '@root/components/LiveHelpContext';
import { PageComponent } from '@root/pages/Type';
import { RouteHref, Router as i18nRouter } from '@root/router';
import {
    AUTH_TOKEN_COOCKIE,
    AuthUser,
    SocialLoginValidateResponse,
    userAuthSocialLoginValidate,
} from '@root/store/auth';
import { AppDispatch, RootState } from '@root/store/types';
import getPropertyFromCtx from '@root/utils/getPropertyFromCtx';
import withLayout from '@root/utils/withLayout';
import withNoAuth from '@root/utils/withNoAuth';
import withTitle from '@root/utils/withTitle';
import { setCookie } from 'cookies-next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AuthLayout from '../AuthLayout';
import Redirecting from '../Redirecting';
import Container from './Container';
import SocialLoginConnector from './SocialLoginConnector';
import SocialLoginTwoAuth from './SocialLoginTwoAuth';

type LoginProps = {
    currentLanguage: string | null;
    oauthToken: string | null;
    user: AuthUser | null;
    socialResponse: SocialLoginValidateResponse | null;
};

const Login: PageComponent<LoginProps> = ({
    currentLanguage,
    user,
    oauthToken,
    socialResponse,
}) => {
    useTranslation('login');
    const captcha = useCaptcha();

    const { wantHide } = useLiveHelp();
    const { setVisible } = captcha;

    useEffect(() => {
        i18nRouter.prefetchRoute('ui_services_list', currentLanguage);
        setVisible(true);
        wantHide(true);
        return () => {
            setVisible(false);
            wantHide(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (socialResponse !== null) {
        if (oauthToken !== null) {
            return <SocialLoginTwoAuth />;
        }
        return <SocialLoginConnector response={socialResponse} />;
    }

    if (user !== null) {
        return <Redirecting />;
    }

    return <Container />;
};

const mapStateToProps = (state: RootState) => ({
    currentLanguage: state.i18n.currentLanguage,
    oauthToken: state.auth.oauthToken,
    user: state.auth.user,
});

export const getServerSideProps = withNoAuth(async (ctx) => {
    const { store, req, res } = ctx;

    let socialResponse: SocialLoginValidateResponse | null = null;

    const provider = getPropertyFromCtx(ctx, 'provider');
    const code = getPropertyFromCtx(ctx, 'code') || '';
    const returnTo = getPropertyFromCtx(ctx, 'returnTo');
    if (provider) {
        socialResponse = await (store.dispatch as AppDispatch)(
            userAuthSocialLoginValidate(provider, code),
        );

        const currentLanguage = store.getState().i18n.currentLanguage;

        if (socialResponse.success) {
            const token = socialResponse?.token;
            setCookie(AUTH_TOKEN_COOCKIE, token, {
                req,
                res,
                path: '/',
                domain:
                    process.env.NODE_ENV !== 'production'
                        ? undefined
                        : '.vpsnet.com',
                secure: true,
            });
            if (returnTo) {
                return {
                    redirect: {
                        destination: returnTo,
                        permanent: false,
                    },
                };
            }
            return {
                redirect: {
                    destination: RouteHref('ui_services_list', currentLanguage),
                    permanent: false,
                },
            };
        }
    }

    return {
        props: {
            socialResponse,
        },
    };
});

export default withTitle(
    {
        translateKey: 'pageTitle',
        keywordsKey: 'pageKeywords',
        descriptionKey: 'pageDescription',
        default: 'Login page',
        namespace: 'login',
        route: 'auth_login',
    },
    withLayout(AuthLayout, connect(mapStateToProps)(Login)),
);
