import Link from '@root/components/Link';
import { Trans, useTranslation } from 'react-i18next';
import { PageComponent } from '@root/pages/Type';
import { withFormContentProps } from '@root/utils/withForm';
import styles from './styles.module.scss';
import { Button, Captcha, Password, Field } from '@components/Form/Index';
import SocialLoginContainer from './SocialLoginContainer';
import LinkToAuthenticate from '@root/components/Auth/LinkToAuthenticate';

type ContentProps = withFormContentProps;

const Content: PageComponent<ContentProps> = ({
    submitting,
    isValid,
    errorMessage,
    onSubmitForm,
}) => {
    const { t } = useTranslation('login');

    return (
        <div className={`${styles.login_container} ${styles.open_login}`}>
            <div className={styles.login_inner}>
                <div className="row">
                    <div
                        className={`col-12 col-md-6 col-lg-6 ${styles.login_form_container}`}
                    >
                        <h4>
                            <Trans i18nKey="title" ns="login">
                                Prisijungimas
                            </Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="description" ns="login">
                                Iveskite savo prisijungimo duomenis noredami
                                prisijungti.
                            </Trans>
                        </p>

                        {errorMessage && (
                            <p className={styles.form_error}>
                                <Trans
                                    i18nKey="form.error"
                                    ns="login"
                                    values={{ error: errorMessage }}
                                >
                                    Klaida: [[error]]
                                </Trans>
                            </p>
                        )}

                        <form onSubmit={onSubmitForm}>
                            <div className="form-row">
                                <div
                                    className={`form-group form-field col-sm-12 col-lg-10 col-xl-8 ${styles.form_control} ${styles.username}`}
                                >
                                    <Field
                                        id="login_email"
                                        name="email"
                                        label={t('form.email', {
                                            defaultValue: 'El. paštas',
                                            ns: 'login',
                                        })}
                                        disabled={submitting}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div
                                    className={`form-group form-field col-sm-12 col-lg-10 col-xl-8 ${styles.form_control} ${styles.password}`}
                                >
                                    <Password
                                        id="login_password"
                                        name="password"
                                        label={t('form.password', {
                                            defaultValue: 'Slaptažodis',
                                            ns: 'login',
                                        })}
                                        disabled={submitting}
                                        idicator={false}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group form-field col-sm-12 col-lg-10 col-xl-8">
                                    <LinkToAuthenticate href="auth_password_reset" shallowBack passHref>
                                        <a>
                                            <Trans
                                                i18nKey="links.rememberPassword"
                                                ns="login"
                                            >
                                                Priminti Slaptažodį
                                            </Trans>
                                        </a>
                                    </LinkToAuthenticate>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0">
                                    <Captcha />
                                </div>
                            </div>

                            <div className="form-row">
                                <div
                                    className={`form-group form-field col-12 col-lg-10 col-xl-8 ${styles.btn_container}`}
                                >
                                    <Button
                                        type="submit"
                                        className={`btn btn-tertiary ml-md-auto ${styles.btn}`}
                                        isLoading={submitting}
                                        disabled={!isValid}
                                    >
                                        <Trans i18nKey="form.button" ns="login">
                                            Prisijungti
                                        </Trans>
                                    </Button>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div
                                className={`col-sm-12 col-lg-10 col-xl-8 ${styles.or_container}`}
                            >
                                <div
                                    className={styles.or}
                                    data-content={t('orTranslate', {
                                        defaultValue: 'arba',
                                        ns: 'login',
                                    })}
                                />
                            </div>
                        </div>

                        <SocialLoginContainer />
                    </div>
                    <div
                        className={`col-12 col-md-6 col-lg-6 ${styles.register_container}`}
                    >
                        <h3>
                            <Trans i18nKey="registration.title" ns="login">
                                Esate naujas vartotojas
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="registration.description"
                                ns="login"
                            >
                                Užsiregistruokite dabar. Tai lengva ir greita!
                            </Trans>
                        </p>
                        <LinkToAuthenticate href="auth_registration" shallowBack passHref>
                            <a className={`btn btn-primary ${styles.btn}`}>
                                <Trans i18nKey="links.registration" ns="login">
                                    Registracija
                                </Trans>
                            </a>
                        </LinkToAuthenticate>
                    </div>
                </div>
            </div>
            <ul className={styles.sub_links}>
                <li>
                    <Link href="landing_services_reasons_technology">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.features"
                                ns="landingNavigation"
                            >
                                Privalumai
                            </Trans>
                        </a>
                    </Link>
                </li>
                <li>
                    <Link href="landing_services_about_company">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.aboutus"
                                ns="landingNavigation"
                            >
                                Apie mus
                            </Trans>
                        </a>
                    </Link>
                </li>
                <li>
                    <Link href="landing_services_faq">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.help"
                                ns="landingNavigation"
                            >
                                Pagalba
                            </Trans>
                        </a>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Content;
