import { useCallback, useEffect, useState } from 'react';
import useInterval from './useInterval';
import useTimeout from './useTimeout';

function useTimeAction(callback: () => void, delay: number | null) {
    const [timeoutsec, setTimeoutsec] = useState(
        delay !== null ? delay / 1000 : 0,
    );
    const updateTimeout = useCallback(() => {
        setTimeoutsec((_sec) => _sec - 1);
    }, [timeoutsec]);
    useEffect(() => {
        if (delay !== null) {
            setTimeoutsec(delay / 1000);
        } else {
            setTimeoutsec(0);
        }
    }, [delay]);
    useInterval(updateTimeout, timeoutsec > 0 ? 1000 : null);
    useTimeout(callback, delay);
    return { timeoutsec };
}

export default useTimeAction;
