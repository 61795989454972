import GithubLogo from '@static/img/socials/github.svg';
import GoogleLogo from '@static/img/socials/google.svg';
import FacebookLogo from '@static/img/socials/facebook.svg';
import styles from './styles.module.scss';
import SocialLoginItem from './SocialLoginItem';
import { AppDispatch } from '@root/store/types';
import {
    SocialLoginResponse,
    userAuthSocialLoginRequest,
} from '@root/store/auth';
import { connect } from 'react-redux';
import { PageComponent } from '@root/pages/Type';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import useRouteHref from '@root/utils/useRouteHref';
import { Trans, useTranslation } from 'react-i18next';
import getHostForRedirect from '@root/utils/getHostForRedirect';

type SocialLoginContainerProps = {
    handleUserAuthSocialLogin: (
        name: string,
        redirectUrl: string,
    ) => Promise<SocialLoginResponse>;
};

const SocialLoginContainer: PageComponent<SocialLoginContainerProps> = ({
    handleUserAuthSocialLogin,
}) => {
    const { t } = useTranslation('login');
    const router = useRouter();
    const query = useMemo(() => {
        const { nextRoute, ...rest } = router.query;
        return rest;
    }, [router]);
    const redirectUrl = useRouteHref(router.query.nextRoute as string, {
        ...query,
        showLogin: true,
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleLogin = useCallback(
        (name: string) => () => {
            handleUserAuthSocialLogin(
                name,
                getHostForRedirect() + redirectUrl,
            ).then((data) => {
                if (!data.url) {
                    setErrorMessage(
                        t('socialLogin.errors.fatalError', {
                            defaultValue: 'Klaida. Prašome pabandyti vėliau.',
                        }),
                    );
                    return;
                }
                if (!data.success) {
                    setErrorMessage(
                        t('socialLogin.errors.fatalError', {
                            defaultValue: 'Klaida. Prašome pabandyti vėliau.',
                        }),
                    );
                    return;
                }
                router.push(data.url);
            });
        },
        [router, handleUserAuthSocialLogin],
    );
    return (
        <div className="row">
            <div className="col-sm-12 col-lg-10 col-xl-8">
                {errorMessage && (
                    <p className={styles.social_form_error}>
                        <Trans
                            i18nKey="socialLogin.error"
                            ns="userRegistration"
                            values={{ errorMessage }}
                        >
                            Klaida: [[errorMessage]]
                        </Trans>
                    </p>
                )}
                <div className={styles.social_container}>
                    <SocialLoginItem
                        logo={<GithubLogo />}
                        className={styles.github}
                        onClick={handleLogin('github')}
                        title="Github"
                    />
                    <SocialLoginItem
                        logo={<GoogleLogo />}
                        className={styles.google}
                        onClick={handleLogin('google')}
                        title="Google"
                    />
                    <SocialLoginItem
                        logo={<FacebookLogo />}
                        className={styles.facebook}
                        onClick={handleLogin('facebook')}
                        title="Facebook"
                    />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleUserAuthSocialLogin: (name: string, redirectUrl: string) =>
        dispatch(userAuthSocialLoginRequest(name, redirectUrl)),
});

export default connect(null, mapDispatchToProps)(SocialLoginContainer);
