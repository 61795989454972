import ErrorIcon from '@components/Ui/Icons/Error/Index';
import SuccessIcon from '@components/Ui/Icons/Success/Index';
import Link from '@root/components/Link';
import { PageComponent } from '@root/pages/Type';
import { SocialLoginValidateResponse } from '@root/store/auth';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import LinkToAuthenticate from '@root/components/Auth/LinkToAuthenticate';

const RegistrationButton: PageComponent = () => {
    return (
        <LinkToAuthenticate href="auth_registration" shallowBack passHref>
            <a className={`btn btn-primary ${styles.btn}`}>
                <Trans i18nKey="links.registration" ns="login">
                    Registracija
                </Trans>
            </a>
        </LinkToAuthenticate>
    );
};

type SocialLoginConnectorProps = {
    response: SocialLoginValidateResponse;
};

const SocialLoginConnector: PageComponent<SocialLoginConnectorProps> = ({
    response,
}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { t } = useTranslation('login');

    useEffect(() => {
        if (response.providerNotFound) {
            setErrorMessage(
                t('socialLogin.errors.providerNotFound', {
                    defaultValue:
                        'Socialinis tinklas nerastas. Prašome pabandyti dar kartą.',
                }),
            );
            return;
        }
        if (response.invalidToken) {
            setErrorMessage(
                t('socialLogin.errors.invalidToken', {
                    defaultValue:
                        'Klaidinga uzklausa. Prašome pabandyti dar kartą.',
                }),
            );
            return;
        }
        if (response.blocked) {
            setErrorMessage(
                t('socialLogin.errors.blocked', {
                    defaultValue: 'Vartotojas yra užblokuotas.',
                }),
            );
            return;
        }
        if (response.userNotFound) {
            setErrorMessage(
                t('socialLogin.errors.userNotFound', {
                    defaultValue: 'Vartotojas nerastas.',
                }),
            );
            return;
        }
        if (!response.success) {
            setErrorMessage(
                t('socialLogin.errors.fatalError', {
                    defaultValue: 'Klaida. Prašome pabandyti veliau.',
                }),
            );
            return;
        }
        // removeSocialCode();
    }, []);

    return (
        <div className={`${styles.login_container} ${styles.open_login}`}>
            <div className={styles.login_inner}>
                <div className="row">
                    <div
                        className={`col-12 col-md-6 col-lg-6 ${styles.login_form_container}`}
                    >
                        <h4>
                            <Trans i18nKey="socialLogin.title" ns="login">
                                Prisijungimas per socialinius tinklus
                            </Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="socialLogin.description" ns="login">
                                Paprastas budas prisijungti prie paskyros
                            </Trans>
                        </p>
                        <div className="row">
                            <div className="col-sm-12 col-lg-10 col-xl-6">
                                <div className={styles.connector_status}>
                                    {errorMessage && (
                                        <>
                                            <ErrorIcon />
                                            <p className={styles.form_error}>
                                                <Trans
                                                    i18nKey="socialLogin.error"
                                                    ns="login"
                                                    values={{ errorMessage }}
                                                >
                                                    Klaida: [[errorMessage]]
                                                </Trans>
                                            </p>
                                        </>
                                    )}
                                    {!errorMessage && (
                                        <>
                                            <SuccessIcon />
                                            <Trans
                                                i18nKey="socialLogin.redirecting"
                                                ns="login"
                                            >
                                                Prijungiama
                                            </Trans>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`col-12 col-md-6 col-lg-6 ${styles.register_container}`}
                    >
                        <h3>
                            <Trans i18nKey="registration.title" ns="login">
                                Esate naujas vartotojas
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="registration.description"
                                ns="login"
                            >
                                Užsiregistruokite dabar. Tai lengva ir greita!
                            </Trans>
                        </p>
                        <RegistrationButton />
                    </div>
                </div>
            </div>
            <ul className={styles.sub_links}>
                <li>
                    <Link href="landing_services_reasons_technology">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.features"
                                ns="landingNavigation"
                            >
                                Privalumai
                            </Trans>
                        </a>
                    </Link>
                </li>
                <li>
                    <Link href="landing_services_about_company">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.aboutus"
                                ns="landingNavigation"
                            >
                                Apie mus
                            </Trans>
                        </a>
                    </Link>
                </li>
                <li>
                    <Link href="landing_services_faq">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.help"
                                ns="landingNavigation"
                            >
                                Pagalba
                            </Trans>
                        </a>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default SocialLoginConnector;
