import getConfig from 'next/config';

const getHostForRedirect = () => {
    const { publicRuntimeConfig } = getConfig();

    if (publicRuntimeConfig.APP_STAGE === "development") {
        const {protocol, hostname, port} = window.location
        if (parseInt(port) !== 80 && parseInt(port) !== 443) {
            return `${protocol}//${hostname}:${port}`;
        }
        return `${protocol}//${hostname}`;
    }

    return publicRuntimeConfig.HOST_URL;
};

export default getHostForRedirect;
