import Link from '@root/components/Link';
import { Trans } from 'react-i18next';
import { PageComponent } from '@root/pages/Type';
import { withFormContentProps } from '@root/utils/withForm';
import styles from './styles.module.scss';
import { Button } from '@components/Form/Index';
import TwoAuth from '@root/components/TwoAuth/TwoAuth';
import LinkToAuthenticate from '@root/components/Auth/LinkToAuthenticate';

type TwoAuthContentProps = withFormContentProps & {
    type: 'pin' | 'app';
};

const TwoAuthContent: PageComponent<TwoAuthContentProps> = ({
    submitting,
    isValid,
    errorMessage,
    onSubmitForm,
    type,
}) => {
    return (
        <div className={`${styles.login_container} ${styles.open_login}`}>
            <div className={styles.login_inner}>
                <div className="row">
                    <div
                        className={`col-12 col-md-6 col-lg-6 ${styles.login_form_container}`}
                    >
                        <h4>
                            <Trans i18nKey="twoAuth.title" ns="login">
                                2-fa
                            </Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="twoAuth.description" ns="login">
                                Iveskite koda, noredami prisijungti
                            </Trans>
                        </p>

                        {errorMessage && (
                            <p className={styles.form_error}>
                                <Trans
                                    i18nKey="twoAuth.form.error"
                                    ns="login"
                                    values={{ errorMessage }}
                                >
                                    Klaida: [[errorMessage]]
                                </Trans>
                            </p>
                        )}

                        <form onSubmit={onSubmitForm}>
                            <div className="form-row">
                                <div
                                    className={`form-group form-field col-sm-12 col-lg-10 col-xl-8 ${styles.form_control} ${styles.twoAuthCode}`}
                                >
                                    <TwoAuth
                                        pinResend={false}
                                        type={type}
                                        autoFocus
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group form-field col-12 col-lg-10 col-xl-8">
                                    <Button
                                        type="submit"
                                        className={`btn btn-tertiary d-block ml-md-auto ${styles.btn}`}
                                        isLoading={submitting}
                                        disabled={!isValid}
                                    >
                                        <Trans
                                            i18nKey="twoAuth.form.confirmButton"
                                            ns="login"
                                        >
                                            Patvirtinti
                                        </Trans>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        className={`col-12 col-md-6 col-lg-6 ${styles.register_container}`}
                    >
                        <h3>
                            <Trans i18nKey="registration.title" ns="login">
                                Esate naujas vartotojas
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="registration.description"
                                ns="login"
                            >
                                Užsiregistruokite dabar. Tai lengva ir greita!
                            </Trans>
                        </p>
                        <LinkToAuthenticate
                            href="auth_registration"
                            shallowBack
                            passHref
                        >
                            <a className={`btn btn-primary ${styles.btn}`}>
                                <Trans i18nKey="links.registration" ns="login">
                                    Registracija
                                </Trans>
                            </a>
                        </LinkToAuthenticate>
                    </div>
                </div>
            </div>
            <ul className={styles.sub_links}>
                <li>
                    <Link href="landing_services_reasons_technology">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.features"
                                ns="landingNavigation"
                            >
                                Privalumai
                            </Trans>
                        </a>
                    </Link>
                </li>
                <li>
                    <Link href="landing_services_about_company">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.aboutus"
                                ns="landingNavigation"
                            >
                                Apie mus
                            </Trans>
                        </a>
                    </Link>
                </li>
                <li>
                    <Link href="landing_services_faq">
                        <a>
                            <Trans
                                i18nKey="servicesTitle.links.help"
                                ns="landingNavigation"
                            >
                                Pagalba
                            </Trans>
                        </a>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default TwoAuthContent;
