import { PageComponent } from '@root/pages/Type';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import AuthCode from '../AuthCode';
import { FieldErrorMessage } from '../Form/Index';
import styles from './styles.module.scss';

type TwoAuthAppProps = {
    autoFocus?: boolean;
};

const TwoAuthApp: PageComponent<TwoAuthAppProps> = ({ autoFocus = false }) => {
    const [, { touched, error }] = useField('twoAuthCode');
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const handleOnChange = (res: string) => {
        if (res.length === 6) {
            setFieldValue('twoAuthCode', res);
        } else {
            setFieldValue('twoAuthCode', '');
        }
    };
    const onFocus = () => {
        setFieldTouched('twoAuthCode', true);
    };
    return (
        <div className={styles.container}>
            <div className={styles.app_container}>
                <label className={styles.label}>
                    <Trans ns="uiComponentsTwoAuth" i18nKey="form.twoAuthApp">
                        Autorizacijos kodas
                    </Trans>
                </label>
                <AuthCode
                    onChange={handleOnChange}
                    onFocus={onFocus}
                    allowedCharacters="numeric"
                    autoFocus={autoFocus}
                    inputClassName={classNames(
                        'form-control',
                        styles.code_item,
                        {
                            [styles.invalid]: touched && error,
                        },
                    )}
                    containerClassName={styles.code_app_container}
                />
                <Form.Text id={`TwoAuthAppHelpBlock`} muted>
                    <Trans
                        ns="uiComponentsTwoAuth"
                        i18nKey="form.twoAuthAppHint"
                    >
                        Naudojant programele
                    </Trans>
                </Form.Text>
                <FieldErrorMessage name={'twoAuthCode'} />
            </div>
        </div>
    );
};

export default TwoAuthApp;
