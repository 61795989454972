import { useTranslation } from 'react-i18next';
import { useHeaderContext } from '@root/components/HeaderContext';
import { PageComponent } from '@root/pages/Type';
import TwoAuthContent from './TwoAuthContent';
import withForm, {
    FormHandlerInside,
    withFormProps,
} from '@root/utils/withForm';
import PrettyError from '@root/utils/errors/PrettyError';
import {
    CaptchaContextState,
    useCaptcha,
} from '@root/components/CaptchaProvider';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '@root/store/types';
import { Router as i18nRouter } from '@root/router';
import { ServerData } from '@root/utils/axios';
import {
    SocialLoginTwoAuthFormValues,
    SocialLoginTwoAuthResponse,
    userAuthSocialTwoAuth,
} from '@root/store/auth';
import { listReset } from '@root/store/services';
import { useRouter } from 'next/router';

const initialValues: SocialLoginTwoAuthFormValues = {
    twoAuthCode: undefined,
};

type SocialLoginTwoAuthProps = withFormProps<SocialLoginTwoAuthFormValues> & {
    currentLanguage: string | null;
    handleUserAuthSocialTwoAuth: (
        values: SocialLoginTwoAuthFormValues,
        captcha?: CaptchaContextState,
    ) => Promise<ServerData<SocialLoginTwoAuthResponse>>;
};

const SocialLoginTwoAuth: PageComponent<SocialLoginTwoAuthProps> = ({
    currentLanguage,
    submitting,
    isValid,
    errorMessage,
    handleForm,
    handleUserAuthSocialTwoAuth,
}) => {
    const { closeSocial, returnToLogin } = useHeaderContext();
    const captcha = useCaptcha();
    const router = useRouter();
    const { t } = useTranslation('login');
    const dispatch = useDispatch<AppDispatch>();

    const formSubmit: FormHandlerInside<SocialLoginTwoAuthFormValues> = ({
        values,
    }) => {
        return handleUserAuthSocialTwoAuth(values, captcha).then((response) => {
            if (response.invalidTwoAuthCode) {
                throw new PrettyError(
                    t('form.errors.invalidTwoAuthCode', {
                        defaultValue: 'Neteisingas 2-autentifikavimo kodas.',
                    }),
                );
            }
            if (!response.success) {
                throw new PrettyError(
                    t('form.errors.fatalError', {
                        defaultValue:
                            'Nepavyko prisijungti. Prašome mėginti dar kartą.',
                    }),
                );
            }
            closeSocial();
            dispatch(listReset());
            if (returnToLogin) {
                return router.push(returnToLogin);
            }
            return i18nRouter.pushRoute(
                'ui_services_list',
                {},
                currentLanguage,
            );
        });
    };

    return (
        <TwoAuthContent
            type={'app'}
            onSubmitForm={handleForm(formSubmit)}
            submitting={submitting}
            isValid={isValid}
            errorMessage={errorMessage}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    currentLanguage: state.i18n.currentLanguage,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleUserAuthSocialTwoAuth: (
        values: SocialLoginTwoAuthFormValues,
        captcha?: CaptchaContextState,
    ) => dispatch(userAuthSocialTwoAuth(values, captcha)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    withForm<SocialLoginTwoAuthFormValues>({
        initialValues,
    })(SocialLoginTwoAuth),
);
