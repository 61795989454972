import { PageComponent } from '@root/pages/Type';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

type SocialLoginItemProps = {
    className?: string;
    logo: ReactNode;
    onClick?: () => void;
    title: string;
};
const SocialLoginItem: PageComponent<SocialLoginItemProps> = ({
    className,
    logo,
    onClick,
    title
}) => {
    return (
        <button
            className={classNames(styles.social_item, className)}
            onClick={(e) => {
                e.preventDefault();
                onClick && onClick();
            }}
        >
            {logo}
            <span>{title}</span>
        </button>
    );
};

export default SocialLoginItem;
