import { RouteHref } from '@root/router';
import { RootState } from '@root/store/types';
import { useSelector } from 'react-redux';

const useRouteHref = (
    name: string,
    params?: unknown,
    locale?: string | null,
): string => {
    const currentLanguage = useSelector(
        (state: RootState) => state.i18n.currentLanguage,
    );
    let localeNew = locale;
    if (typeof locale === 'undefined' || locale === null) {
        localeNew = currentLanguage;
    }
    return RouteHref(name, localeNew, params);
};

export default useRouteHref;
